@import '../css/bootstrap.min.css';
@import '../css/fonts.css';
@media (max-width: 575.98px) {
  .hidden-lt-sm {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .hidden-lt-md {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .hidden-lt-lg {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .hidden-lt-xl {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .hidden-gt-sm {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden-gt-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-gt-lg {
    display: none !important;
  }
}
.flex-row {
  height: 100%;
  display: flex;
}
.flex-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flex-maxis-center {
  justify-content: center;
}
.flex-caxis-center {
  align-items: center;
}
.btn {
  width: fit-content;
  padding: 4px 17px;
  font-size: 10pt;
  font-weight: bold;
  border: 1px solid black;
}
@media (max-width: 991.98px) {
  .btn {
    font-size: 8pt;
  }
}
.btn-primary {
  background: #4384ff;
  color: white;
}
.btn-secondary {
  background: #dedede;
  color: #444444;
  border: 1px solid #afafaf;
}
.btn-danger {
  background: darkred;
  color: whitesmoke;
  border: 1px solid #f10000;
}
.btn-success {
  background: darkgreen;
  color: white;
}
.width-auto {
  width: auto;
}
.margin-none {
  margin: 0 !important;
}
.margin-x {
  margin-bottom: 5px !important;
}
.margin-xx {
  margin-bottom: 10px !important;
}
.margin-xxx {
  margin-bottom: 15px !important;
}
.margin-4x {
  margin-bottom: 20px !important;
}
.margin-5x {
  margin-bottom: 25px !important;
}
.margin-6x {
  margin-bottom: 30px !important;
}
.margin-b-none {
  margin-bottom: 0 !important;
}
.margin-b-x {
  margin-bottom: 5px !important;
}
.margin-b-xx {
  margin-bottom: 10px !important;
}
.margin-b-xxx {
  margin-bottom: 15px !important;
}
.margin-b-4x {
  margin-bottom: 20px !important;
}
.margin-b-5x {
  margin-bottom: 25px !important;
}
.margin-b-6x {
  margin-bottom: 30px !important;
}
.margin-r-none {
  margin-right: 0 !important;
}
.margin-r-x {
  margin-right: 5px !important;
}
.margin-r-xx {
  margin-right: 10px !important;
}
.margin-r-xxx {
  margin-right: 15px !important;
}
.margin-r-4x {
  margin-right: 20px !important;
}
.margin-r-5x {
  margin-right: 25px !important;
}
.margin-r-6x {
  margin-right: 30px !important;
}
.margin-l-none {
  margin-left: 0 !important;
}
.margin-l-x {
  margin-left: 5px !important;
}
.margin-l-xx {
  margin-left: 10px !important;
}
.margin-l-xxx {
  margin-left: 15px !important;
}
.margin-l-4x {
  margin-left: 20px !important;
}
.margin-l-5x {
  margin-left: 25px !important;
}
.margin-l-6x {
  margin-left: 30px !important;
}
.margin-t-none {
  margin-top: 0 !important;
}
.margin-t-x {
  margin-top: 5px !important;
}
.margin-t-xx {
  margin-top: 10px !important;
}
.margin-t-xxx {
  margin-top: 15px !important;
}
.margin-t-4x {
  margin-top: 20px !important;
}
.margin-t-5x {
  margin-top: 25px !important;
}
.margin-t-6x {
  margin-top: 30px !important;
}
input {
  padding-right: 10px;
  font-weight: 500;
}
h1 {
  font-size: calc(20px + 12 * ((100vw - 500px) / 1500));
  font-weight: bold;
  margin-bottom: calc(10px + 0 * ((100vw - 500px) / 1500));
}
h2 {
  font-size: calc(19px + 11 * ((100vw - 500px) / 1500));
  margin-bottom: calc(5px + 0 * ((100vw - 500px) / 1500));
}
h3 {
  font-size: calc(17px + 10 * ((100vw - 500px) / 1500));
}
h4 {
  font-size: calc(16px + 9 * ((100vw - 500px) / 1500));
}
h5 {
  font-size: calc(15px + 7 * ((100vw - 500px) / 1500));
}
h6 {
  font-size: calc(14px + 5 * ((100vw - 500px) / 1500));
}
div.title {
  font-size: calc(22px + 12 * ((100vw - 500px) / 1500));
  font-weight: 900;
  margin-bottom: calc(12px + 0 * ((100vw - 500px) / 1500));
}
p {
  padding-bottom: calc(10px + 0 * ((100vw - 500px) / 1500));
}
label {
  margin: 0;
}
a:hover {
  cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: IRANSans !important;
  font-size: calc(14px + 2 * ((100vw - 500px) / 1500));
  font-weight: normal;
  text-align: right;
  direction: rtl;
  background: white;
}
.MainMenuContainer {
  width: 100%;
  height: 5.5vh;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid #e2e2e2;
}
@media (max-width: 991.98px) {
  .MainMenuContainer {
    height: 9vh;
    top: auto;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e2e2e2;
  }
  .MainMenuContainer .MainMenuItemContainer {
    justify-content: center;
    align-content: center;
  }
  .MainMenuContainer .MainMenuItemContainer .MenuItem {
    width: 100px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  .MainMenuContainer .MainMenuItemContainer .MenuItem label.MenuItemLabel {
    margin: 0;
    margin-top: 5px;
    background: none !important;
    font-size: 8pt !important;
  }
}
@media (max-width: 991.98px) and (max-width: 575.98px) {
  .MainMenuContainer .MainMenuItemContainer .MenuItem {
    width: auto;
  }
}
@media (max-width: 991.98px) and (max-width: 575.98px) {
  .MainMenuContainer .MainMenuItemContainer .MenuItem label.MenuItemLabel {
    font-size: 6pt !important;
  }
}
.MainMenuContainer .MainMenuContainerBrand {
  height: 32px;
  cursor: pointer;
}
.MainMenuContainer.top {
  height: 5.5vh;
  top: 0;
  left: 0;
  justify-content: space-between;
}
.MainMenuContainer.top .MainMenuContainerMenuIcon {
  font-size: 24px;
  cursor: pointer;
}
.MainMenuContainer.top .MainMenuContainerBrand {
  height: 24px;
}
.MainMenuContainer .MainMenuItemContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.MainMenuContainer .MainMenuItemContainer .MenuItem {
  height: 100%;
  border-bottom: none;
}
.MainMenuContainer .MainMenuItemContainer .MenuItem:hover {
  border-left: none;
  background: #f3f3f3;
}
.SideMenuContainer {
  width: 18%;
  height: calc(100vh - 5.5vh);
  position: fixed;
  top: 5.5vh;
  z-index: 1;
  background: #ffffff;
  overflow-y: auto;
  border-left: 1px solid #e2e2e2;
}
.SideMenuContainer::-webkit-scrollbar {
  width: 0;
}
.SideMenuContainer::-webkit-scrollbar-track {
  display: none;
}
.SideMenuContainer::-webkit-scrollbar-thumb {
  display: none;
}
.SideMenuContainer::-webkit-scrollbar-thumb:hover {
  display: none;
}
@media (max-width: 991.98px) {
  .SideMenuContainer {
    width: 30%;
  }
}
@media (max-width: 767.98px) {
  .SideMenuContainer {
    width: 40%;
  }
}
.MainSectionContainer {
  height: calc(100vh - 5.5vh);
  padding: 20px calc(18%) 20px 0;
  position: relative;
  top: 5.5vh;
  left: 0;
  z-index: 0;
  overflow-y: scroll;
}
.MainSectionContainer::-webkit-scrollbar {
  width: 0;
}
.MainSectionContainer::-webkit-scrollbar-track {
  display: none;
}
.MainSectionContainer::-webkit-scrollbar-thumb {
  display: none;
}
.MainSectionContainer::-webkit-scrollbar-thumb:hover {
  display: none;
}
@media (max-width: 991.98px) {
  .MainSectionContainer {
    padding: 20px 0;
  }
  .MainSectionContainer::after {
    content: "";
    display: block;
    height: calc(9vh);
    width: 100%;
  }
}
.SearchContainer {
  width: 220px;
  height: 32px;
  position: relative;
}
.SearchContainer input {
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8pt;
  border: 1px solid #e2e2e2;
}
.SearchContainer input::placeholder {
  color: #333333;
  opacity: 1;
}
.SearchContainer .SearchIconContainer {
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SearchContainer .SearchIconContainer span {
  height: 16px;
}
.MenuItem {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
}
.MenuItem.active {
  background: #7ab5ff;
}
.MenuItem:hover {
  border-left: 10px solid #3467a7;
}
.MenuItem.sub {
  background: #f7f7f7;
}
.MenuItem.sub::before {
  width: 5px;
  height: 5px;
  background: #333333;
  border-radius: 50%;
  content: ' ';
}
.MenuItem img.MenuItemIcon {
  height: 20px;
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .MenuItem img.MenuItemIcon {
    height: 16px;
  }
}
.MenuItem span.MenuItemIcon {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .MenuItem span.MenuItemIcon {
    height: 16px;
  }
}
.MenuItem label.MenuItemLabel {
  margin-right: 10px;
  font-size: 10pt;
  color: #333333;
  cursor: pointer;
}
.MenuItem label.MenuItemLabel.labeled {
  background: #f1f0ed;
  border-radius: 15px;
  font-size: 8pt;
  color: black;
  padding: 2px 10px;
}
@media (max-width: 575.98px) {
  .MenuItem label.MenuItemLabel {
    font-size: 8pt;
  }
}
.UserEntity {
  display: flex;
}
.UserEntity .UserEntityAvatar img {
  height: 24px;
  border: 2px solid #f1f0ed;
  border-radius: 50%;
}
.UserEntity .UserEntityAvatar span {
  font-size: 24px;
}
.UserEntity .UserEntityInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.UserEntity .UserEntityInfo label.UserEntityInfoName {
  font-size: 8pt;
  margin-right: 10px;
}
fieldset.SectionField {
  min-inline-size: unset;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  padding: 20px 15px;
  background: white;
  border: 1px solid #e3e3e3;
}
fieldset.SectionField:last-child {
  margin-bottom: 0;
}
fieldset.SectionField:first-child {
  margin-bottom: 20px;
}
fieldset.SectionField legend {
  color: #a2a2a2;
  font-weight: 900;
}
.FormInputContainer {
  width: 100٪;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.FormInputContainer input.FormInput,
.FormInputContainer select.FormInput {
  width: 100%;
  padding: 7px 15px;
  background: white;
  border: 1px solid #e3e3e3;
  color: #8b8b8b;
  font-size: 10pt;
  font-weight: bold;
}
.FormInputContainer input.FormInput:disabled,
.FormInputContainer select.FormInput:disabled {
  background: #f7f7f7;
}
.FormInputContainer select.FormInput {
  width: fit-content;
}
.MultiFormInputContainer {
  display: flex;
  flex-direction: row;
}
.MultiFormInputContainer input.MultiFormInput {
  flex-grow: 1;
}
.MultiFormInputContainer input.MultiFormInput:first-child {
  border-left: none;
}
.MultiFormInputContainer button {
  flex-grow: 1;
}
label.FormInputLabel {
  margin-bottom: 10px !important;
  font-weight: bold;
  font-size: 10pt;
}
label.Labeled {
  padding: 3px 7px;
  font-size: 8pt;
  border-radius: 12px;
}
label.Labeled.LabeledLight {
  background: #c0d6ff;
  color: #333333;
}
label.Labeled.LabeledSuccess {
  background: darkgreen;
  color: whitesmoke;
}
label.Labeled.LabeledDanger {
  background: darkred;
  color: whitesmoke;
}
label.Labeled.LabeledWarning {
  background: #fff579;
}
.Quote {
  margin-bottom: 25px;
  border-right: 5px solid darkgray;
  padding-right: 10px;
  font-size: font-so;
  color: #8a8a8a;
}
.Quote:first-child {
  margin-bottom: 25px;
}
.Quote:last-child {
  margin-bottom: 0;
}
.Quote .QuoteHeader {
  margin-bottom: 15px;
}
.Gallery {
  width: 100%;
  padding: 20px 0;
  display: flex;
  overflow-x: auto;
}
.Gallery::-webkit-scrollbar {
  width: 10px;
}
.Gallery::-webkit-scrollbar-track {
  background: whitesmoke;
}
.Gallery::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}
.Gallery::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.Gallery img {
  height: 220px;
}
.Gallery .GalleryItemContainer {
  height: 220px;
  margin-right: 15px;
  position: relative;
}
.Gallery .GalleryItemContainer:first-child {
  margin-right: 0;
}
.Gallery .GalleryItemContainer img.GalleryItemImage {
  height: 220px;
}
.Gallery .GalleryItemContainer .GalleryItemToolbar {
  width: 100%;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
table.Table {
  width: 100%;
}
table.Table tr {
  width: 100%;
  border: 1px solid #eaeaea;
}
table.Table tr:nth-child(2n) {
  background: #f9f9f9;
}
table.Table td {
  padding: 10px 15px;
  flex-grow: 1;
  border-right: 1px solid #eaeaea;
}
table.Table thead tr td {
  font-weight: 600;
}
